<template>
  <div>
    <div class="overmask" :style="returnaCor('alphaLogin', false, true)"></div>
    <div class="container" id="Login">
      <loading :show="carregando"></loading>
      <erro-conexao :logo="logo" :erroMensagem="errorConexao"></erro-conexao>

      <div v-if="!errorConexao" class="mt-5">
        <div style="padding: 15% 0"></div>
        <div>
          <b-col class="text-center">
            <div class="text-white" style="margin-top: ">
              <h4 class="text-uppercase text-shadow">Verificação</h4>
              <div class="alert alert-warning my-3" v-if="form.dadosRes">
                <span>
                  Foi enviado {{ getCanalEnvio() }} {{ getCanalUsuario() }} com
                  um <strong>Código de Verificação</strong> que deve ser
                  preenchido para a sua segurança
                </span>
              </div>
            </div>
            <b-form-group id="input-group-2" label-for="input-2">
              <the-mask
                mask="######"
                style="text-transform: uppercase"
                id="input-2"
                class="form-control text-center"
                v-model="form.codigoVerificacao"
                placeholder="Código de Verificação"
                name="Código de Verificação"
                :masked="true"
                v-validate="{ required: true }"
                :state="validateState('Código de Verificação')"
                :class="validateClass('Código de Verificação')"
              />
            </b-form-group>
            <center v-if="contando">
              <h5 class="text-white">
                Você poderá solicitar um novo código em:
              </h5>
              <h1 class="text-white">{{ contador }}</h1>
            </center>
            <center v-else>
              <b-button
                variant="link"
                class="text-white link-senha"
                @click="enviarCodigoNovamenteProxy()"
                >Enviar código novamente</b-button
              >
            </center>
            <div :class="visibilidade ? 'visivel' : 'invisivel'">
              <b-button
                type="submit"
                class="btn-block mt-3"
                variant="dark"
                :style="returnaCor('botaoLogin', true, false)"
                @click="validarCodigo()"
                :disabled="!visibilidade && desabilitar"
                >Validar</b-button
              >
            </div>
          </b-col>
        </div>

        <b-modal
          :id="MODAL_REENVIO"
          title="Reenviar código de verificação"
          hide-footer
        >
          <b-form-group label="Selecione a forma de envio">
            <b-form-radio
              v-model="selectedMethodSend"
              :value="WHATSAPP"
              class="py-3"
              >WhatsApp</b-form-radio
            >
            <b-form-radio v-model="selectedMethodSend" :value="SMS"
              >SMS / E-mail</b-form-radio
            >
          </b-form-group>
          <b-button
            class="mt-3"
            variant="outline-primary"
            block
            @click="sendEnviarCodigoNovamente"
            >Enviar</b-button
          >
        </b-modal>
      </div>
      <rodape></rodape>
    </div>
  </div>
</template>

<script>
import locale from '../locale';

import Rodape from './../shared/rodape.vue';
import erroConexao from './../shared/erro-conexao.vue';
import loading from './../shared/loading.vue';
import { LOGO, WHATSAPP, SMS } from './../shared/global';

import service from '../services';
import utils from './../services/utils';
import { buscaDadosEmpresa } from './../services/dados-empresa';
import { returnCor } from './../services/return-cor';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        codigoVerificacao: '',
      },
      score: 0,
      errorConexao: '',
      carregando: true,
      logo: LOGO,
      termo: '<p></p>',
      exibeTermo: false,
      value: 80,
      max: 100,
      nomeEmpresa: null,
      emailEmpresa: null,
      temaCor: {
        botaoLogin: '#000000',
        alphaLogin: '#000000',
        botaoFormularios: '#000000',
      },
      contando: false,
      contador: 45,
      desabilitar: false,
      sentWhatsapp: 0,
      selectedMethodSend: WHATSAPP,
      WHATSAPP: WHATSAPP,
      SMS: SMS,
      MODAL_REENVIO: 'modal-reenviar-codigo',
    };
  },
  computed: {
    visibilidade() {
      return this.form.codigoVerificacao.length === 6;
    },
  },
  components: {
    loading,
    'erro-conexao': erroConexao,
    rodape: Rodape,
  },
  methods: {
    returnaCor(chave, comSombra, backgroundTransparent) {
      return returnCor(this.temaCor, chave, comSombra, backgroundTransparent);
    },
    showScore(score) {
      this.score = score;
    },
    selecionaTab(valor) {
      this.form.formSelecionado = valor;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    enviarCodigoNovamenteProxy() {
      if (!this.sentWhatsapp) {
        return this.enviarCodigoNovamente();
      }

      this.$bvModal.show(this.MODAL_REENVIO);
    },
    enviarCodigoNovamente(forceSendSMS = false) {
      this.carregando = true;
      const data = this.form;
      data.forceSendSMS = forceSendSMS;

      service
        .post('Login', 'enviarCodigoNovamente', data)
        .then((res) => {
          this.carregando = false;

          this.countSentWhatsapp(res);
          this.setTypeSent(res);

          this.$bvToast.toast('O código de Verificação foi enviado', {
            title: 'Atenção!',
            solid: true,
            variant: 'success',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
          this.contagemRegressiva();
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
    },
    validarCodigo() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.carregando = true;
        this.desabilitar = true;
        service
          .post('Login', 'validarCodigo', this.form)
          .then((res) => {
            this.carregando = false;
            this.desabilitar = false;
            if (res.data.dadosRes) {
              localStorage.setItem(
                'dadosRes',
                JSON.stringify(res.data.dadosRes),
              );
            }

            this.$router.push({
              path: res.data.redirect,
            });
          })
          .catch((e) => {
            this.carregando = false;
            this.desabilitar = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    async buscaDadosEmpresa() {
      const result = await buscaDadosEmpresa();

      this.logo = result.logo;
      this.temaCor = result.temaCor;
      this.form.idEmpresa = result.dadosRes.id_empresa;
      this.form.dadosRes = result.dadosRes;

      this.form.dadosRes.sentWhatsapp = result.dadosRes.optInWhatsapp || false;

      this.carregando = false;
    },
    getCanalEnvio() {
      if (this.form.dadosRes.sentWhatsapp && this.form.dadosRes.celular) {
        return `uma mensagem no Whatsapp`;
      }

      if (this.form.dadosRes.celular) {
        return `um SMS`;
      }

      return `um e-mail`;
    },
    getCanalUsuario() {
      if (
        (this.form.dadosRes.sentWhatsapp && this.form.dadosRes.celular) ||
        this.form.dadosRes.celular
      )
        return `para o número ${utils.escondeCelular(
          this.form.dadosRes.celular,
        )}`;

      return `para ${utils.escondeEmail(this.form.dadosRes.email)}`;
    },
    contagemRegressiva() {
      this.mostrarContador();
      localStorage.setItem('contando', 'contando');
      const diminuir1Segundo = setInterval(() => {
        this.contador--;
        this.contador === 0 && clearInterval(diminuir1Segundo);
        this.contador === 0 && localStorage.removeItem('contando');
        this.contador === 0 && this.mostrarContador();
      }, 1000);
    },
    mostrarContador() {
      this.contando = !this.contando;
      this.contador = 45;
    },
    verificarSeContando() {
      if (localStorage.getItem('contando')) {
        this.contagemRegressiva();
      }
    },
    countSentWhatsapp(res) {
      this.sentWhatsapp += res.data.sentWhatsapp ? 1 : 0;
    },
    setTypeSent(res) {
      this.form.dadosRes.sentWhatsapp = res.data.sentWhatsapp;
    },
    sendEnviarCodigoNovamente() {
      this.$bvModal.hide(this.MODAL_REENVIO);

      const forceSendSMS = this.selectedMethodSend === SMS;
      this.enviarCodigoNovamente(forceSendSMS);
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosEmpresa();
    this.verificarSeContando();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.link-senha {
  font-size: 13px;
  text-decoration: underline;
}

.visivel {
  opacity: 1;
  transition: 0.7s;
}

.invisivel {
  opacity: 0;
  transition: 0.7s;
}

body {
  background-image: url('./../assets/images/1.png');
}
</style>
